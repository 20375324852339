import NotFoundApp from 'apps/NotFound'
import { getCommonStaticProps } from 'commonPage'
import commonMessages from 'locales/es/common'

export default NotFoundApp

export const getStaticProps = async () => {
  const commonStaticProps = await getCommonStaticProps()

  return {
    props: {
      messages: {
        ...commonMessages,
      },
      ...commonStaticProps,
    },
  }
}
