import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Page from 'components/Page'
import sx from './styles'

const NotFound = () => {
  return (
    <Page>
      <Grid
        container
        sx={sx.root}
      >
        <Grid
          item
          xs={12}
        >
          <Typography
            variant="h1"
            align="center"
            sx={sx.title}
          >
            <FormattedMessage id="NOT_FOUND_TITLE" />
          </Typography>
        </Grid>
      </Grid>

    </Page>
  )
}
export default memo(NotFound)
