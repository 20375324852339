const styles = {
  root: {
    height: '100%',
  },
  title: {
    color: 'secondary.main',
    fontWeight: 700,
  },
  notFoundInner: {
    paddingY: 4,
    justifyContent: 'center',
  },
}

export default styles
